@font-face {
  font-family: "droid";
  src: url("../../../assets/fonts/TheSans-Plain.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "fontawesome";
  src: url("../../../assets/fonts/fontawesome.ttf") format("truetype"),
    url("../../../assets/fonts/fontawesome.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="fa-"],
[class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fontawesome" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-chalkboard:before {
  content: "\e923";
}
.fa-check:before {
  content: "\e924";
}
.fa-sign-in-alt:before {
  content: "\e925";
}
.fa-times:before {
  content: "\e926";
}
.fa-search:before {
  content: "\e922";
}
.fa-arrow-left:before {
  content: "\e900";
}
.fa-bars:before {
  content: "\e901";
}
.fa-book-reader:before {
  content: "\e902";
}
.fa-box:before {
  content: "\e903";
}
.fa-calendar-alt:before {
  content: "\e904";
}
.fa-calendar-check:before {
  content: "\e905";
}
.fa-calendar-plus:before {
  content: "\e906";
}
.fa-chalkboard-teacher:before {
  content: "\e907";
}
.fa-chart-bar:before {
  content: "\e908";
}
.fa-clock:before {
  content: "\e909";
}
.fa-icon-skill-read::before {
  content: "\e946";
}
.fa-cogs:before {
  content: "\e90a";
}
.fa-comments:before {
  content: "\e90b";
}
.fa-compress:before {
  content: "\e90c";
}
.fa-dollar-sign:before {
  content: "\e90d";
}
.fa-edit:before {
  content: "\e90e";
}
.fa-expand:before {
  content: "\e90f";
}
.fa-eye-slash:before {
  content: "\e910";
}
.fa-eye:before {
  content: "\e911";
}
.fa-file-invoice-dollar:before {
  content: "\e912";
}
.fa-file-pdf:before {
  content: "\e913";
}
.fa-id-card:before {
  content: "\e914";
}
.fa-moon:before {
  content: "\e915";
}
.fa-plus:before {
  content: "\e916";
}
.fa-print:before {
  content: "\e917";
}
.fa-search-dollar:before {
  content: "\e918";
}
.fa-sign-out-alt:before {
  content: "\e919";
}
.fa-sort-numeric-up-alt:before {
  content: "\e91a";
}
.fa-sticky-note:before {
  content: "\e91b";
}
.fa-sun:before {
  content: "\e91c";
}
.fa-trash-alt:before {
  content: "\e91d";
}
.fa-user-lock:before {
  content: "\e91e";
}
.fa-user:before {
  content: "\e91f";
}
.fa-users:before {
  content: "\e920";
}
.fa-window-close:before {
  content: "\e921";
}
