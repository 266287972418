.mat-tooltip {
  padding: 0.5rem;
  background-color: var(--background-basic-color-3);
  color: var(--color-primary-400);
}
.mat-paginator-container {
  padding-top: 0.5rem !important;
  justify-content: flex-start !important;
  padding-bottom: 0.5rem;
  .mat-icon-button {
    margin: 0 0.1rem;
    line-height: 30px;
    background-color: var(--background-basic-color-3);
    color: var(--color-primary-400);
    width: 30px;
    outline: none;
    height: 30px;
  }
  .mat-icon-button[disabled="true"] {
    color: var(--text-hint-color);
  }
  .mat-paginator-range-label {
    direction: ltr;
  }
}

table {
  border: 1px solid nb-theme(card-border-color);
  line-height: 1.5em;
  border-collapse: collapse;

  border-spacing: 0;
  display: table;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
  // box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
  //   0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
table tr th,
table tr th a {
  font-family: nb-theme(smart-table-header-font-family);
  font-size: nb-theme(smart-table-header-font-size);
  font-weight: nb-theme(smart-table-header-font-weight);
  line-height: nb-theme(smart-table-header-line-height);
  color: nb-theme(color-primary-500);
}

table tr td {
  font-family: nb-theme(smart-table-font-family);
  font-size: nb-theme(smart-table-font-size);
  font-weight: nb-theme(smart-table-font-weight);
  line-height: nb-theme(smart-table-line-height);
  color: nb-theme(smart-table-fg);
}

table tr th,
table tr td {
  position: relative;
  padding: nb-theme(smart-table-padding);
  vertical-align: middle;
}
table td,
table th {
  border: 1px solid nb-theme(smart-table-separator);
}
table tr:first-child th {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
  border-right: 1px solid nb-theme(card-border-color);
}
table tr td:last-child,
table tr th:last-child {
  border-right: 0;
  border-left: 1px solid nb-theme(card-border-color);
}
table tr.ng2-smart-titles {
  th {
    padding: nb-theme(smart-table-padding);
    @include nb-ltr(padding-right, 1.75rem);
    @include nb-rtl(padding-left, 1.75rem);
  }

  th a {
    &:hover,
    &:active,
    &:visited {
      color: nb-theme(smart-table-header-fg);
      text-decoration: none;
    }
    &.sort.asc,
    &.sort.desc {
      font-weight: nb-theme(smart-table-header-font-weight);

      &::after {
        border-bottom-color: nb-theme(smart-table-fg-secondary);
        border-width: 0.375rem;
        position: absolute;
        margin: 0;
        @include nb-ltr(right, 0.75rem);
        @include nb-rtl(left, 0.75rem);
        top: 50%;
        transform: translate(0, -50%);
      }

      &.desc::after {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }
}

thead tr {
  background: nb-theme(smart-table-header-bg);

  &.ng2-smart-filters th {
    padding: nb-theme(smart-table-filter-padding);

    .ng2-smart-filter input {
      line-height: nb-theme(smart-table-line-height);
    }
  }
}

tbody tr {
  &.selected,
  &:hover {
    background: nb-theme(smart-table-bg-active);
  }

  &:nth-child(2n) {
    background-color: nb-theme(smart-table-bg-even);
  }
}

// Actions
th.ng2-smart-actions-title-add {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    padding: nb-theme(smart-table-filter-padding);
    border-color: nb-theme(smart-table-add-button-border-color);
    background-color: nb-theme(smart-table-add-button-background-color);
    color: nb-theme(smart-table-add-button-text-color);
    border-radius: 0.375rem;

    &:focus {
      border-color: nb-theme(smart-table-add-button-focus-border-color);
    }

    &:hover {
      text-decoration: none;
      background-color: nb-theme(smart-table-add-button-hover-background-color);
      border-color: nb-theme(smart-table-add-button-hover-border-color);
    }

    &:active {
      background-color: nb-theme(
        smart-table-add-button-active-background-color
      );
      border-color: nb-theme(smart-table-add-button-active-border-color);
    }
  }
}

.ng2-smart-actions {
  padding: 0;
  // fix for 100% height child
  height: 1px;

  ng2-st-tbody-edit-delete,
  ng2-st-tbody-create-cancel,
  ng2-st-actions {
    display: flex;
    height: 100%;
  }

  a.ng2-smart-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    font-size: 2rem;
    color: nb-theme(smart-table-fg-secondary);

    &:not(:last-child) {
      border-right: 1px solid nb-theme(smart-table-separator);
    }

    &:hover {
      text-decoration: none;
    }
  }

  .ng2-smart-action-add-create:hover,
  .ng2-smart-action-edit-edit:hover,
  .ng2-smart-action-edit-save:hover {
    color: nb-theme(smart-table-action-add-edit-hover-text-color);
  }

  .ng2-smart-action-add-cancel:hover,
  .ng2-smart-action-delete-delete:hover,
  .ng2-smart-action-edit-cancel:hover {
    color: nb-theme(smart-table-action-cancel-delete-hover-text-color);
  }
}

// TODO: remove this hack, when it will be possible to identify the whole row in the edit state
table-cell-edit-mode {
  display: block;
  margin: -7.5px -12px;
}

//Paging
.ng2-smart-pagination-nav {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;

  .ngx-pagination {
    display: flex;
    padding: 0;

    .disabled,
    .disabled span:focus,
    .disabled span:hover {
      background-color: nb-theme(smart-table-paging-hover);
      color: nb-theme(smart-table-paging-disabled-text-color);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ngx-pagination .current {
    padding: 0;
    background: transparent;
    color: #fefefe;
    cursor: default;
  }
  .ngx-pagination .disabled {
    padding: 0;
  }
  .ngx-pagination li {
    display: flex;
  }
  .ngx-pagination {
    font-family: nb-theme(smart-table-paging-font-family);
    font-size: nb-theme(smart-table-paging-font-size);
    line-height: nb-theme(smart-table-paging-line-height);
    border: nb-theme(smart-table-paging-border-color) solid
      nb-theme(smart-table-paging-border-width);
    border-radius: nb-theme(smart-table-border-radius);
    .small-screen {
      display: none;
    }
    li {
      overflow: hidden;

      &:not(:last-child) {
        @include nb-ltr(
          border-right,
          1px solid nb-theme(smart-table-separator)
        );
        @include nb-rtl(border-left, 1px solid nb-theme(smart-table-separator));
      }

      a.page-link-prev,
      a.page-link-next {
        font-size: nb-theme(smart-table-paging-font-size);
        line-height: nb-theme(smart-table-paging-line-height);
      }

      a,
      > span {
        font-size: nb-theme(smart-table-paging-font-size);
        line-height: nb-theme(smart-table-paging-line-height);
        background-color: transparent;
        text-decoration: none;
        color: nb-theme(smart-table-paging-page-text-color);
        padding: 0.75rem 1.25rem;
        border: none;

        &:focus {
          border-color: nb-theme(smart-table-paging-page-focus-border-color);
          color: nb-theme(smart-table-paging-page-focus-text-color);
        }

        &:hover {
          background-color: nb-theme(smart-table-paging-hover);
          border-color: nb-theme(smart-table-paging-hover-border-color);
          color: nb-theme(smart-table-paging-hover-text-color);
          text-decoration: none;
        }
      }

      &:first-child {
        a,
        > span {
          @include nb-ltr() {
            border-top-left-radius: nb-theme(smart-table-border-radius);
            border-bottom-left-radius: nb-theme(smart-table-border-radius);
          }
          @include nb-rtl() {
            border-top-right-radius: nb-theme(smart-table-border-radius);
            border-bottom-right-radius: nb-theme(smart-table-border-radius);
          }
        }
      }

      &:last-child {
        a,
        > span {
          @include nb-ltr() {
            border-top-right-radius: nb-theme(smart-table-border-radius);
            border-bottom-right-radius: nb-theme(smart-table-border-radius);
          }
          @include nb-rtl() {
            border-top-left-radius: nb-theme(smart-table-border-radius);
            border-bottom-left-radius: nb-theme(smart-table-border-radius);
          }
        }
      }

      &.current {
        a,
        a:hover,
        a:focus,
        > span,
        > span:hover,
        > span:focus {
          color: nb-theme(smart-table-paging-fg-active);
          background-color: nb-theme(smart-table-paging-bg-active);
        }
      }
    }
  }
}
