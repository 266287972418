.table-search-container {
  width: 200px;
  margin: 1rem 0;
}

tr.example-detail-row {
  height: 0;
}
.expanded-td {
  padding: 0;
  border: none;
}
.example-element-row td {
  border-bottom-width: 0;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
}
.example-element-detail div {
  padding: 0.5rem 0.3rem;
}

.example-element-diagram {
  min-width: 80px;
  padding: 8px;
  font-weight: lighter;
  margin: 8px 0;
  height: 104px;
}

.example-element-symbol {
  font-weight: bold;
  font-size: 40px;
  line-height: normal;
}

.example-element-description {
  padding: 16px;
}

.example-element-description-attribution {
  opacity: 0.5;
}
.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}
.danger-star::after {
  content: "*";
  color: var(--danger);
}
.user-picture {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-size: cover;
  background-repeat: no-repeat;
}
.mat-tab-list .mat-tab-labels {
  display: none !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  display: none !important;
}
.mat-tab-body {
  width: 100%;
  max-width: 100%;
}
.mat-tab-body-wrapper {
  width: 100%;
  max-width: 100%;
}
.mat-tab-body.mat-tab-body-active {
  overflow: unset !important;
}

.mat-tab-group {
  width: 100%;
}
.mat-tab-body-content {
  overflow: unset !important;
}
.mat-tab-body-wrapper {
  overflow: unset !important;
}
#cards-comments{
  min-width: 55vw;
}
.card-comment{
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  text-align: right;
  margin: 0;
  margin-bottom: 1rem;
}
.card-comment .caption{
  padding: 16px;
  font-size: 15px;
}
.card-comment img{
  width: 100%;
  height: auto;
  object-fit: cover;
}
.card-comment .name{
  color: #050505;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.card-comment .date{
  color: #70757a;
  font-weight: 500;
  margin: 0;
  direction: ltr;
}
.card-comment .comment{
  margin: 16px 0;
}
.card-comment .card-images{
  height: 100px;
  display: flex;
}
.card-comment .card-images img{
  height: 100px;
}
 .popup-overlay{
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.popup-box{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-width: 100%;
  height: auto;
  z-index: 1001;
}
.popup-box img{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
/* Loader */
#loader {
  display: inline-block;
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
  color: #007bff !important;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: spin .75s linear infinite;
  animation: spin .75s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}