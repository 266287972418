@import "./themes";

@mixin nb-overrides() {
  nb-auth-block {
    width: 100% !important;
    margin: unset !important;
    max-width: unset !important;
  }
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
    text-align: right;
  }
  nb-select.size-medium button {
    padding: 0.4375rem 1.125rem 0.4375rem 2.2rem !important;
  }
  nb-menu .menu-item .menu-icon {
    margin: 0 0 0 0.5rem !important;
    font-size: 1rem;
  }
  nb-checkbox label {
    cursor: pointer;
  }
  nb-icon {
    height: var(--icon-height) !important;
  }
  nb-card-header {
    color: var(--color-primary-500);
  }
  nb-card {
    border-width: 1px;
    box-shadow: 0 0 7px var(--card-border-color);
  }
  nb-dialog-container {
    @media screen and (max-width: 576px) {
      // overflow: hidden scroll;
    }
  }
  .layout-container .content nb-layout-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    nav {
      justify-content: flex-start !important;
      width: 100%;
    }
  }
  .mat-autocomplete-panel {
    background-color: var(--option-list-background-color);
    border-color: var(--option-list-border-color);
    border-style: var(--option-list-border-style);
    border-width: var(---option-list-border-width);
    border-radius: var(--option-list-border-radius);
    box-shadow: var(---option-list-shadow);
    overflow: hidden;
  }
  .mat-option {
    background-color: nb-theme(option-background-color);
    color: nb-theme(option-text-color);
    font-family: nb-theme(option-text-font-family);

    &.mat-active {
      background-color: nb-theme(option-active-background-color);
      color: nb-theme(option-active-text-color);
    }

    &.mat-selected {
      background-color: nb-theme(option-selected-background-color);
      color: nb-theme(option-selected-text-color);
    }

    &:focus {
      background-color: nb-theme(option-focus-background-color);
      color: nb-theme(option-focus-text-color);
      outline: none;

      &.selected {
        background-color: nb-theme(option-selected-focus-background-color);
        color: nb-theme(option-selected-focus-text-color);
      }
    }

    &:hover {
      background-color: nb-theme(option-hover-background-color);
      color: nb-theme(option-hover-text-color);

      &.selected {
        background-color: nb-theme(option-selected-hover-background-color);
        color: nb-theme(option-selected-hover-text-color);
      }
    }

    &.multiple {
      &.selected {
        background-color: nb-theme(option-background-color);
        color: nb-theme(option-text-color);
      }

      &:focus {
        background-color: nb-theme(option-focus-background-color);
        color: nb-theme(option-focus-text-color);
      }
    }
  }
}
